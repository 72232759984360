import { Link } from "react-router-dom"
import {Card} from "antd"


const WeatherDeatailCard = ({weather}) => {
    return(
        <div key={weather.forecastDate}>
          <Card hoverable title={weather.forecastDate + " " + weather.week} className='card'>
            <Link to={"/Weather/Detail/" + weather.forecastDate}>
              <img src={'https://media.istockphoto.com/vectors/cute-flat-sun-icon-vector-id1124567572?k=20&m=1124567572&s=612x612&w=0&h=qA2-ugQviG9uGvpn5-K90sK9w5QZjd3TetULc_5VECc='}
                style={{height:"50px"}}/>
            </Link>
            <li>Maximum Temperature: {weather.forecastMaxtemp.value}C</li>
            <li>Minimum Tempearture : {weather.forecastMintemp.value}C</li>
          </Card>
        </div> 
    );


}

export default WeatherDeatailCard;