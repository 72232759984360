import {Routes, Route} from 'react-router-dom';
import CarParkAllDetail from './CarParkAllDetail'
import CarParkDetail from './CarParkDetail'
const CarParkRoutes = () => {
    return(
        <div>
            <Routes>
                <Route exact path="/Details" element={<CarParkAllDetail/>}/>
                <Route exact path="/Detail/:id" element={<CarParkDetail/>}/>
            </Routes>
        </div>
    )
}
export default CarParkRoutes;