import './App.css';
import 'antd/dist/antd.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Auth from './Auth';
import Login from './Login';
import NotFound from './NotFound'
import AppHeader from './AppHeader'
import EngineRoutes from './EngineRoutes';
import WeatherRoutes from './WeatherRoutes';
import CarParkRoutes from './CarParkRoutes';
import TodoRoutes from './TodoRoutes';
import FoodRoutes from './FoodRoutes';
import ContextProvider from './Utils/MyContext';
import Home from './Home'
import Cashier from './Cashier'

const App = () => {
      return (
        
        <BrowserRouter>
          <ContextProvider>
            <AppHeader></AppHeader>
              <Routes>
              <Route exact path="/" element={<Home/>}/>
                <Route exact path="/Login" element={<Login/>}/>
                {/* <Route element={<Auth/>}> */}
                <Route>
                  <Route exact path="/weather/*" element={<WeatherRoutes/>}/>
                  <Route exact path="/engine/*" element={<EngineRoutes/>}/>
                  <Route exact path="/carPark/*" element={<CarParkRoutes/>}/>
                  <Route exact path="/todolist/*" element={<TodoRoutes/>}/>
                  <Route exact path="/food/*" element={<FoodRoutes/>}/>
                  <Route exact path="/cashier" element={<Cashier/>}/>
                  <Route path="*" element={<NotFound/>}/>
                </Route>
                <Route path="*" element={<NotFound/>}/>
              </Routes>
          </ContextProvider>
        </BrowserRouter>
  );
}

export default App;
