import { useParams,Link } from "react-router-dom"
import { useContext,useEffect, useState } from 'react';
import Axios from "axios";
import {Card,Button} from "antd"
import {Context} from './Utils/MyContext';
const Detail = () => {
    const [singleWeatherInfo,setSingleWeatherInfo] =useState(null);
    const{isAuth,isLoading,setIsLoading} = useContext(Context);
    let {date} = useParams()

    useEffect(() => {
      if(isAuth){
        setIsLoading(true);
        Axios.get('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=fnd&lang=tc')
        .then(res => {      
          setSingleWeatherInfo(res.data.weatherForecast.find((element)=>{return element.forecastDate === date}))
          setIsLoading(false);
        })
      }

    },[])
    return(
        <div>
          {
            isLoading &&  <h1 className="loadingMssg">Data Loading...</h1>
          }
          {
            singleWeatherInfo != null &&
              <Card title={singleWeatherInfo.forecastDate}>
                <li>{singleWeatherInfo.week}</li>
                <li>{singleWeatherInfo.forecastWeather}</li>
                <li>{singleWeatherInfo.forecastWind}</li>
                <li>Maximum Temperature: {singleWeatherInfo.forecastMaxtemp.value}C</li>
                <li>Minimum Tempearture : {singleWeatherInfo.forecastMintemp.value}C</li>
                <Link to={"/Weather/Details"}>
                  <Button>Go Back</Button>
                </Link>
              </Card>
          }
        </div>
    );

}

export default Detail;