import {Routes, Route} from 'react-router-dom';
import TodoAllDetail from './TodoAllDetail'
const ToDoRoutes = () => {
    return(
        <div>
            <Routes>
                <Route exact path="/Details" element={<TodoAllDetail/>}/>
                {/* <Route exact path="/Detail/:id" element={<CarParkDetail/>}/> */}
            </Routes>
        </div>
    )
}
export default ToDoRoutes;