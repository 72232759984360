import { useContext,useEffect, useState,useRef } from 'react';
import Axios from "axios";
import {Context} from './Utils/MyContext';
import EngineDetailCard from './EngineDetailCard'
import {Row,Col,Input,Button,Select} from "antd"
import financialYearArray from './FinancialYearOption'
import useUpdateEffect from './customHooks/useUpdateEffect'
import useEffectOnce from './customHooks//useEffectOnce'
import useDebounce from './customHooks/useDebounce'
const EngineDetails = () => {
    const [engineDetails,setEnginDetails] = useState(null);
    const [tempEngineDetails,setTempEngineDetails] = useState(null);
    const context = useContext(Context);
    const [search,setSearch] = useState("");
    const [financialYear,setFinancialYear] = useState('');
    const [searchDisabled,setSearchDisabled] = useState(true);
    const searchRef = useRef();
    const { Option } = Select;
    useEffectOnce(() => {
        if(context.isAuth){
          context.setIsLoading(true);
          Axios.get('https://www.dsd.gov.hk/datagovhk/data/expenditure_of_project_eng.json')
          .then(res => {      
            setEnginDetails(res.data);
            context.setIsLoading(false) 
            calculateExpenditure(res.data);
            filterEngineDetails(res.data);
          })
        };
      })

      // useUpdateEffect(()=>{
      //   if(engineDetails !== null){
      //       calculateExpenditure(engineDetails)
      //     }
      // },[search,financialYear])

      useDebounce(() => {
        filterEngineDetails(engineDetails); 
        calculateExpenditure(engineDetails)}
        ,1000,[search,financialYear])

      // const handleInputChange = (event) => {
      //   setTimeout(() => {setSearch(event.target.value)},1000)
      // }

      // const handleInputChange = (event) => {
      //   searchRef.current = event.target.value;
      //   if (searchRef.current ===''){
      //     setSearch(searchRef.current);
      //     setSearchDisabled(true);
      //   }
      //   else{
      //     setSearchDisabled(false);
      //   }
      // }
      const handleInputChange = (event) => {
        setSearch(event.target.value);
      }
      // const handleOnClickSearch = () => {
      //     setSearch(searchRef.current);
      // }

      // handle financial year filter
      const handleSelectChange = (value) => {
        if (value === "All"){
          setFinancialYear("");
        }
        else{
          setFinancialYear(value)
        }
      }
      // calculate ependiture
      const calculateExpenditure = (data) => {
        let expenditure = 0;
        let filterEngineSearch = data.filter((engineDetail) => (
          engineDetail['Project Title'].includes(search) && engineDetail['﻿Financial Year'].includes(financialYear)
        ))

        for (let step = 0; step < filterEngineSearch.length; step++){
            expenditure += parseFloat(filterEngineSearch[step]['Actual Expenditure (HK$ million)'])
        }   
        context.setExpenditure(Math.round(expenditure));
      }
      
      // filter value by serach and dropdown financial year input
      const filterEngineDetails = (data) => {
        setTempEngineDetails(data.filter((engineDetail) => (
          engineDetail['Project Title'].includes(search) && engineDetail['﻿Financial Year'].includes(financialYear)
        )))
      }

      return (
        <div>
          
          {/* <Input ref={searchRef} onChange={handleInputChange}></Input> */}
          <Input onChange={handleInputChange}></Input>
          <Select defaultValue={''} style={{ width: 120 }} onChange={handleSelectChange}>
            {
              financialYearArray.map((financialYear) => 
              (
                <Option key={financialYear} value={financialYear}>{financialYear}</Option>
              )
              )
            }
          </Select>
          {/* <Button onClick={handleOnClickSearch} disabled={searchDisabled}>Search</Button> */}
          {
            context.isLoading && <h1 className="loadingMssg">Data Loading...</h1>
          }
          <Row>
              {
              tempEngineDetails != null && tempEngineDetails.map((engineDetail) => 
              (
                <Col span ={12} key={tempEngineDetails.findIndex((element) => element === engineDetail ) }>
                  <EngineDetailCard engine={engineDetail} index={engineDetails.findIndex((element) => element === engineDetail )}></EngineDetailCard>
                </Col>
              ))}
          </Row>
        </div>
  );


}

export default EngineDetails;