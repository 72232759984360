import {Routes, Route} from 'react-router-dom';
import FoodAllDetails from './FoodAllDetail';
const FoodRoutes = () => {
    return(

        <Routes>
            <Route exact path="/Details" element={<FoodAllDetails/>}/>
        </Routes>
    )

}
export default FoodRoutes;