import { useParams,Link } from "react-router-dom"
import { useContext,useEffect, useState } from 'react';
import Axios from "axios";
import {Context} from './Utils/MyContext';
import EngineDetailCard from "./EngineDetailCard";
import {Button} from "antd"
const Detail = () => {
    const [singleEngineDetail,setSingleEnginDetail] = useState(null);
    const{isAuth,isLoading,setIsLoading} = useContext(Context);
    let {id} = useParams()

    useEffect(() => {
      if(isAuth){
        setIsLoading(true);
        Axios.get('https://www.dsd.gov.hk/datagovhk/data/expenditure_of_project_eng.json')
        .then(res => {      
            setSingleEnginDetail(res.data.find((element)=>{return element['PWP Item No.'] === id}))
          setIsLoading(false);
        })
      }

    },[])
    return(
        <div>
            {
                isLoading &&  <h1 className="loadingMssg">Data Loading...</h1>
            }
            {
                singleEngineDetail != null &&
                    <EngineDetailCard engine={singleEngineDetail}></EngineDetailCard>
            }
            <Link to={"/Engine/Details"}>
                <Button>Go Back</Button>
            </Link>
        </div>
    );

}

export default Detail;