import {Button,Input,Card,Spin,Alert,Layout,Typography} from "antd"
import Axios from "axios";
import {Context} from './Utils/MyContext';
import {useContext,useState} from 'react';
import { serverURL} from './Utils/serverURL';
const TodoAllDetail = ()=>{
    const {Header, Content,Footer} = Layout;
    const {Title, Paragraph, Text, Link} = Typography;
    const context = useContext(Context);
    const [userName,setUserName] = useState(null);
    const [editUserName,setEditUserName] = useState(null);
    const [subscribedToChannel,setSubscribedToChannel] = useState(null);
    const [userList,setUserList] = useState(null);
    const createUser = () => {
        Axios.post(`${serverURL}/subscribers`,
        {
            name:userName,
            subscribedToChannel:subscribedToChannel 
        },
        {
            headers:{
                "authorization":localStorage.getItem('accessToken')
            }
        }
        )
        .then((res)=>console.log(res.data))
        .then(setTimeout( ()=>{getAllUser()},1000))
    }

    const getAllUser = () => {
        if(context.isAuth){
            context.setIsLoading(true);
            Axios.get(`${serverURL}/subscribers`,
            {
                headers:
                {
                    'authorization': localStorage.getItem('accessToken'),
                    'refresh_token' : localStorage.getItem('refreshToken_id')
                }
            }
                )
            .then((res)=>{
                console.log(res.data)
                setUserList(res.data)
                context.setIsLoading(false)
            })
        }
    }

    const save = (element) =>{
        context.setIsLoading(true)
        Axios.patch(`${serverURL}/subscribers/${element._id}`,{name:editUserName},
        {
            headers:{
                "authorization":localStorage.getItem('accessToken'),
                'refresh_token' : localStorage.getItem('refreshToken_id')
            }
        }
        )
        .then((res)=>console.log(res.data))
        .then(
            setTimeout(()=>{getAllUser()},1000)
        )
    }

    const deleteUser = (element) =>{
        context.setIsLoading(true)
        Axios.delete(`${serverURL}/subscribers/${element._id}`,
        {
            headers:{
                "authorization":localStorage.getItem('accessToken')
            }
        }
        )
        .then((res)=>console.log(res.data))
        .then(setTimeout(()=>{getAllUser()},1000))
        }

    return(
        <div>  
            <Content className="site-layout">
                <div className='loginDiv'>
                    <Title>
                        Subscriber
                    </Title>
                    Name:<Input onChange={(event) => setUserName(event.target.value)}/>
                    SubscribedToChannel:<Input onChange={(event) => setSubscribedToChannel(event.target.value)}/>
                    <div>
                        <Button className='button' onClick={createUser}>create user</Button> 
                        <Button className='button' onClick={getAllUser}>get all user</Button> 
                    </div>


                    {
                        context.isLoading &&     <Spin tip="Loading..."></Spin>
                    }
                    {
                        userList != null && userList.map((element)=>{
                            return <Card hoverable className='card' key={element._id} title={element.name}>
                                        <p>_id: {element._id}</p>
                                        <p>name: {element.name}</p>
                                        <p>subscribed: {element.subscribedToChannel}</p>
                                        <p>subscription date: {element.subscribeDate}</p>
                                        <Input className='button' onChange={(event) => setEditUserName(event.target.value)} placeholder="Edit Name"></Input>
                                        <Button className='button' onClick={() => {save(element)}}>edit name</Button>
                                        <Button className='button' onClick={() => {deleteUser(element)}}>delete user</Button>
                                    </Card>
                        })
                    }
                </div>


            </Content>

        </div>
    )

}
export default TodoAllDetail