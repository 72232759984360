const NotFound = () => {

    return(
        <div>
            404 Not FOund
        </div>
        
    )
}

export default NotFound;