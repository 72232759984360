import {useNavigate,Link} from 'react-router-dom';
import { useContext,useRef,useState } from 'react';
import {Button,Menu,Modal} from "antd"
import {Context} from './Utils/MyContext';
import useEffectOnce from './customHooks/useEffectOnce';
import menuArray from './Utils/menuObject';
import Axios from "axios";
import IdleTimer from 'react-idle-timer'
import { serverURL} from './Utils/serverURL';
const AppHeader = () => {

    const context = useContext(Context);
    const navigate = useNavigate();
    const idleTimerRef = useRef(null);
    const sessionRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const logout = () => {
        Axios.delete(`${serverURL}/auth/logout`,{
            headers: {
              'authorization': localStorage.getItem('accessToken'),
              'refresh_token' : localStorage.getItem('refreshToken_id')
            }
          }).then( (res) => {
                context.setAuth(false);
                navigate("/Login");
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken_id')
                sessionStorage.removeItem('userId')
                setIsModalVisible(false);
                clearTimeout(sessionRef);
          }

          )

      }

    const stayActive = () => {
        setIsModalVisible(false);
        clearTimeout(sessionRef);
    }

    
    const menuClick = (e)=> {
        context.setCurrentPage(e.key)
    }     

    const onIdle = () => {
        setIsModalVisible(true)
        sessionRef.current = setTimeout(logout,5 * 60 * 1000)
    }
    useEffectOnce(()=>{
        menuArray.forEach((menuItem) => {
            if((window.location.href).toLowerCase().includes(menuItem.key)){
                context.setCurrentPage(menuItem.key)
            }
        })
    })


    const menuStyle = {
        backgroundColor:'grey',
    }

    const menuItemStyle = {
        color:'white'
    }
    return (
        <div>
            <Menu onClick={menuClick} selectedKeys={[context.currentPage]} mode="horizontal" style={menuStyle}>
                {menuArray.map((menuItem) => 
                    (
                    <Menu.Item key={menuItem.key} >
                        <Link to={menuItem.link} style={ menuItemStyle }>
                            <b>{menuItem.pageName}</b>
                        </Link>
                    </Menu.Item>
                    )
                )}
            </Menu>
            
            {
                context.isAuth && 
                <div>
                    <IdleTimer ref={idleTimerRef}
                        timeout={5 * 60 * 1000}
                        onIdle={onIdle}>
                    </IdleTimer>
                    <Modal title="Not Active" visible={isModalVisible} onOk={logout} onCancel={stayActive} okText="Logout" cancelText="Stay">
                        <p>Are you sure to logout</p>
                    </Modal>
                    <Button className='button' onClick={logout}>Logout</Button>  
               </div>
            }
        </div>
    )
}
export default AppHeader