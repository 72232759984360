import {Button,Input,Row,Col,Card,Layout,Typography} from "antd"
import {Context} from './Utils/MyContext';
import {useNavigate} from 'react-router-dom';
import { useContext,useState} from 'react';
import Axios from "axios";
import { serverURL} from './Utils/serverURL';
import backgroundImage from './image/forest.jpg'
const Login = () => {
    const navigate = useNavigate();
    const context = useContext(Context);
    const [username,setUsername] = useState(null);
    const [password,setPassword] = useState(null);
    const {Header, Content,Footer} = Layout;
    const {Title, Paragraph, Text, Link} = Typography;
    const login = async () => {
        const res = await Axios.post(`${serverURL}/users/getUser`,
        {
            username: username,
            password: password
        })

        if(res != null){
            sessionStorage.setItem('userId',res.data._id)
            await context.getAccessToken()
            setTimeout(() => {
                context.setAuth(true);
                navigate(`/${context.currentPage}/Details`)
            }
                ,100)
        }
        else{
            console.log("user unfound")
        }
      }  

      const createUser = async () => {
        const res = await Axios.post(`${serverURL}/users`,
        {
            username: username,
            password: password
        })
        if(res.data._id == null){
            console.log("error")
            return
        }
        sessionStorage.setItem('userId',res.data._id)
        await context.getAccessToken()
        setTimeout(() => {
            context.setAuth(true);
            navigate(`/${context.currentPage}/Details`)
        }
            ,100)
      }  

      const mediumStyle ={
          backgroundImage: `url(${backgroundImage})`,
          height: '100vh'
      }
    return(
        <div>
            <Content className="site-layout">
                <div className='loginDiv'>
                    <Title>
                        Login
                    </Title>
                    <Input className='button' onChange={(event) => setUsername(event.target.value)} placeholder="Username"></Input>        
                    <Input className='button' type='password' onChange={(event) => setPassword(event.target.value)} placeholder="Password"></Input>
                    <Button className='button' onClick={login}>Login</Button> 
                </div>
            </Content>

            <Content className="site-layout">
                <div className='loginDiv'>
                    <Title>
                        Create Account
                    </Title>
                    <Input className='button' onChange={(event) => setUsername(event.target.value)} placeholder="Username"></Input>        
                    <Input className='button' type='password' onChange={(event) => setPassword(event.target.value)} placeholder="Password"></Input>
                    <Button className='button' onClick={createUser}>Create User </Button>
                </div>
            </Content>

        </div>


        
    )
}

export default Login;