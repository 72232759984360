import { Link } from "react-router-dom"
import {Card} from "antd"


const EngineDeatailCard = ({engine,index}) => {
    
    return(
        <div key={index}>
          <Link to={"/Engine/Detail/" + engine['PWP Item No.']}>
            <img src={'https://t4.ftcdn.net/jpg/02/03/55/25/360_F_203552537_fQY1bICTd1JkME2vBOrC36CyzdrnVE70.jpg'}
              style={{height:"50px"}}/>
          </Link>
          
          <Card title={engine['Project Title']}>
            <li>Financial Year: {engine['﻿Financial Year']}</li>  
            <li>Actual Expenditure (HK$ million): {engine['Actual Expenditure (HK$ million)']}</li>
          </Card>
        </div> 
    );


}

export default EngineDeatailCard;