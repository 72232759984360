import { createContext,useState,useEffect, } from 'react';
import Axios from "axios";
import { serverURL} from './serverURL';
export const Context = createContext();


const ContextProvider = (props) => {
    const [isAuth,setAuth] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [expenditure,setExpenditure] = useState(0);
    const [search,setSearch] = useState("");
    const [currentPage,setCurrentPage] = useState('Weather')
    const [totalAmount,setTotalAmount] = useState(0);
    const [orderNumber,setOrderNumber] = useState(1);
    const [message,setMessage] = useState('');
    const [roastPorkRiceCount,setRoastPorkRiceCount] = useState(0);
    const [roastChickenCount,setChickenCount] = useState(0);

    const getAccessTokenByRefreshToken = async () => {
        try{
            let res = await Axios.post(`${serverURL}/auth/token`,{token:localStorage.getItem('refreshToken_id')})
            localStorage.setItem("accessToken",res.data.accessToken)
        }
        catch(err){
            console.log(err)
        }

    }


    const getAccessToken = async ()=> {
        try{
            let res = await Axios.post(`${serverURL}/auth/login`)
            localStorage.setItem("accessToken",res.data.accessToken)
            localStorage.setItem("refreshToken_id",res.data.refreshToken)
        }
        catch(err){
            console.log(err)
        }
    }

    return (

        <Context.Provider value={
            {currentPage,setCurrentPage,isAuth,setAuth,isLoading,
            setIsLoading,expenditure,setExpenditure,search,setSearch,
             getAccessToken,getAccessTokenByRefreshToken,totalAmount,setTotalAmount,orderNumber,setOrderNumber,message,setMessage,
             roastPorkRiceCount,setRoastPorkRiceCount,roastChickenCount,setChickenCount}}>
            {props.children}
        </Context.Provider>
    )
}
export default ContextProvider;