import { useParams,Link } from "react-router-dom"
import {Card,Button,Layout,Typography} from "antd"
import { useContext,useEffect, useState } from 'react';
import {Context} from './Utils/MyContext';
const Cashier = () => {
    const {Title, Paragraph, Text} = Typography;
    const{orderNumber} = useContext(Context);

    return(
        <>
        <Title>
            點餐完成! 您的點餐編號為{orderNumber} !
            Your order number is {orderNumber}. 
        </Title>
            <Link to={"/food/Details"}>
                <Button>返回 Go Back</Button>
            </Link>  
        </>

    )
}
export default Cashier;