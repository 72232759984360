import './App.css';
import 'antd/dist/antd.css';
import { useContext,useEffect, useState,useRef } from 'react';
import Axios from "axios";
import {Row,Col,Input,Button,Layout,Typography} from "antd"
import {Context} from './Utils/MyContext';
import FoodDetailCard from './FoodDetailCard'
import { useParams,Link } from "react-router-dom"
import emailjs from "emailjs-com"
const FoodAllDetails = () => {
    const {Header, Content,Footer} = Layout;
    const {Title, Paragraph, Text} = Typography;
    const [roastChickenMessage,setRoastChickenMessage] = useState("");
    const [roastPorkRiceMessage,setRoastPorkMessage] = useState("");
    const searchRef = useRef();
    const
      {
        isLoading,totalAmount,setTotalAmount,orderNumber,message,setMessage,
        roastPorkRiceCount,setRoastPorkRiceCount,roastChickenCount,
        setChickenCount,setOrderNumber
      } 
    = useContext(Context);

    useEffect(() => {
      const min = 1;
      const max = 10000;
      const rand = min + Math.random() * (max - min);
      setOrderNumber(rand.toFixed(0));
    },[])

    const templateParams = {
      orderNumber : orderNumber,
      message: roastChickenMessage + roastPorkRiceMessage,
      totalAmount: totalAmount
    };
  
    
    const sendEmail = () => {
      setRoastPorkRiceCount(0);
      setChickenCount(0);
      setTotalAmount(0);
      emailjs.send('service_db9ttum','template_x895a9k', templateParams,'464DsGGmNqEL4cCA7')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      }, (err) => {
        console.log('FAILED...', err);
      });
    }

    useEffect(() => {
      if(roastChickenCount !==0){
        setRoastChickenMessage("燒雞 Roast Chicken X " + roastChickenCount)
      }
      else{
        setRoastChickenMessage("")
      }
      
    },[roastChickenCount])

    useEffect(() => {
      if(roastPorkRiceCount !==0){
        setRoastPorkMessage("叉燒飯 Roast Pork Rice X " + roastPorkRiceCount)
      }
      else{
        setRoastPorkMessage("")
      }
      
    },[roastPorkRiceCount])

      return (
        <div>
          <Content className="site-layout">
           {/* <div className='loginDiv'> */}
           <div>
              <Title className="col-padding">
                主食 Main Menu
              </Title>
              {
                isLoading && <h1 className="loadingMssg">Data Loading...</h1>
              }
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} className="col-padding">
                    <FoodDetailCard image={'https://static6.orstatic.com/userphoto2/photo/1R/1DUR/09UIS58C17E8A2995AB08Dpx.jpg'} 
                        price={'85'} title={'燒雞 Roast Chicken'} comment={'必食️ 🔥🔥🔥'} count={roastChickenCount} setCount={setChickenCount}>
                    </FoodDetailCard>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className="col-padding">
                    <FoodDetailCard image={'https://static5.orstatic.com/userphoto2/photo/1R/1E6K/09WURWCE4EE068936E489Epx.jpg'} 
                        price={'65'} title={'叉燒飯 Roast Pork Rice'} comment={'人氣之選 👍👍👍'}  count={roastPorkRiceCount} setCount={setRoastPorkRiceCount}>
                    </FoodDetailCard>
                </Col>
              </Row>
              <Title className="col-padding">
                總計 Total :$ {totalAmount}
              </Title>
              <Link to={"/cashier"} className="col-padding">
                  <Button onClick={sendEmail}>確認 Confirm</Button>
              </Link>
            </div>
          </Content>
        </div>
  );
}

export default FoodAllDetails;