import './App.css';
import 'antd/dist/antd.css';
import { useContext,useEffect, useState,useRef } from 'react';
import Axios from "axios";
import {Row,Col,Input,Button,Layout,Typography} from "antd"
import {Context} from './Utils/MyContext';
import WeatherDetailCard from './WeatherDetailCard'
const Home = () => {
    const {Header, Content,Footer} = Layout;
    const {Title, Paragraph, Text, Link} = Typography;
    const [weatherInfo,setWeatherInfo] = useState(null)
    const [search,setSearch] = useState("");
    const [searchDisabled,setSearchDisabled] = useState(true);
    const searchRef = useRef();
    const{isAuth,isLoading,setIsLoading} = useContext(Context);
    useEffect(() => {
      if(isAuth){
        setIsLoading(true);
        Axios.get('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=fnd&lang=tc')
        .then(res => {      
          setWeatherInfo(res.data.weatherForecast);
          setIsLoading(false) 
        })
      };
    },[])

    const handleChange = (event) => {
      searchRef.current = event.target.value;
      if (searchRef.current ===''){
        setSearch(searchRef.current);
        setSearchDisabled(true);
      }
      else{
        setSearchDisabled(false);
      }
    }

    const handleOnClickSearch = () => {
        setSearch(searchRef.current);
    } 

      return (
        <div>
          <Content className="site-layout">
           <div className='loginDiv'>
              <Title>
                Weather
              </Title>
              <Input className='button' ref={searchRef} onChange={handleChange}></Input>
              <Button className='button' onClick={handleOnClickSearch} disabled={searchDisabled}>Search</Button>
              {
                isLoading && <h1 className="loadingMssg">Data Loading...</h1>
              }
              {
                weatherInfo != null && 
                  <div>
                    {weatherInfo.generalSituation}
                  </div>
              }
              <Row>
                  {
                  weatherInfo != null && weatherInfo.map((weather) => (
                      weather.forecastDate.includes(search) && 
                        <Col xs={24} sm={24} md={12} lg={12} key={weather.forecastDate} className="col-padding">
                          <WeatherDetailCard weather={weather}></WeatherDetailCard>
                        </Col>
                  ))}
              </Row>
            </div>
          </Content>
        </div>
  );
}

export default Home;