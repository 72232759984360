import {useNavigate} from 'react-router-dom';
import {Button} from 'antd'
const Home = () => {
    const navigate = useNavigate();
    const login = () => {
        navigate('/Login')
    }
      
    return(
        <div>
            <Button onClick={login}>Go to Login Page</Button>   
        </div>
        
    )
}

export default Home;