const menuArray = [

    // {
    //     'key' : 'weather',
    //     'pageName' : 'weather',
    //     'link' : '/weather/Details'
    // },

    // {
    //     'key' : 'engine',
    //     'pageName' : 'engine',
    //     'link' : '/engine/Details'
    // },
    // {
    //     'key' : 'carpark',
    //     'pageName' : 'carpark',
    //     'link' : '/carpark/Details'
    // },
    // {
    //     'key' : 'toDoList',
    //     'pageName' : 'ToDoList',
    //     'link' : '/todolist/Details'
    // },
    {
        'key' : 'food',
        'pageName' :'餐牌 Menu',
        'link' : '/food/Details'
    }

]


export default menuArray;