import { useParams,Link } from "react-router-dom"
import { useContext,useEffect, useState } from 'react';
import Axios from "axios";
import {Card,Button,Layout,Typography} from "antd"
import {Context} from './Utils/MyContext';
const CarParkDetail = () => {
    const {Header, Content,Footer} = Layout;
    const {Title, Paragraph, Text} = Typography;
    const [singleCarParkInfo,setSingleCarParkInfo] =useState(null);
    const{isAuth,isLoading,setIsLoading} = useContext(Context);
    let {id} = useParams()

    useEffect(() => {
      if(isAuth){
        setIsLoading(true);
        Axios.get('https://sps-opendata.pilotsmartke.gov.hk/rest/getCarparkInfos')
        .then(res => {      
            setSingleCarParkInfo(res.data.results.find((element)=>{return element._id === parseInt(id)}))
            setIsLoading(false);
        })
      }

    },[])
    return(
        <div>
          {
            isLoading &&  <h1 className="loadingMssg">Data Loading...</h1>
          }
          { singleCarParkInfo != null &&
            <Content className="site-layout">
              <div className='loginDiv'>
                  <Title>
                    Car Park Detail
                  </Title>
                    <Card className='card' title={singleCarParkInfo.name}>
                      <li>Private car space:{singleCarParkInfo.privateCar.space}</li>
                      { singleCarParkInfo.openingHours != null &&
                          <li>Opening hours : {singleCarParkInfo.openingHours[0].periodStart} to {singleCarParkInfo.openingHours[0].periodEnd}</li>
                      }
                      
                      <Link to={"/carpark/Details"}>
                        <Button>Go Back</Button>
                      </Link>
                    </Card>
                  </div>
                </Content>
          }
        </div>
    );

}

export default CarParkDetail;