import {Routes, Route} from 'react-router-dom';
import EngineAllDetail from './EngineAllDetail';
import EngineDetails from './EngineDetail'
import { useContext} from 'react';
import {Context} from './Utils/MyContext';
const EngineRoutes = () => {
    const {expenditure} = useContext(Context);
    return(
        <div>
            Actual Expenditure (HK$ million){expenditure}
            <Routes>
                <Route exact path="/Details" element={<EngineAllDetail/>}/>
                <Route exact path="/Detail/:id" element={<EngineDetails/>}/>
            </Routes>
        </div>
    )
}
export default EngineRoutes;