import './App.css';
import 'antd/dist/antd.css';
import { useContext,useEffect, useState,useRef } from 'react';
import Axios from "axios";
import {Context} from './Utils/MyContext';
import { Map, Marker,Overlay } from "pigeon-maps"
import {Row,Col,Card,Layout,Typography} from "antd"
import { useNavigate } from 'react-router-dom';
const CarParkAllDetail = () => {
    const {Header, Content,Footer} = Layout;
    const {Title, Paragraph, Text, Link} = Typography;
    const{isAuth,isLoading,setIsLoading} = useContext(Context);
    const [carParkDetails,setCarParkDetails] = useState(null);
    const [carParkInfo,setCarParkInfo] = useState({});
    const [carParkLayoutInfo,setCarParkLayoutInfo] = useState(null);
    const navigate = useNavigate()
    useEffect(() => {
      if(isAuth){
        setIsLoading(true);
        Axios.get('https://sps-opendata.pilotsmartke.gov.hk/rest/getCarparkInfos')
        .then(res => {      
          setCarParkDetails(res.data.results);
          setIsLoading(false) 
          setCarParkInfo(res.data.results[0])
        })
      };
    },[])


      return (
        <div>
          {
            isLoading ? <h1 className="loadingMssg">Data Loading...</h1> :
            carParkDetails != null && 
            <Content className="site-layout">
              <div className='loginDiv'>
                <Title>
                  Car Park Map
                </Title>
              
                  <Row>
                    <Col span={24}>
                      <Map height={600} defaultCenter={[22.302711, 114.177216]} defaultZoom={13}>
                        {carParkDetails.map((carParkDetail)=>{
                          return(
                            <Marker key={carParkDetail._id} width={50} anchor={[carParkDetail.coordinates[1], carParkDetail.coordinates[0]]}
                              color={"red"}  
                              onClick={() => {navigate (`/carpark/Detail/${carParkDetail._id}`)}}
                              onMouseOver={() => {setCarParkLayoutInfo(carParkDetail)}}
                              onMouseOut={() => {setCarParkLayoutInfo(null)}}
                              />
                          )
                        })}
                              {carParkLayoutInfo != null &&
                                <Overlay anchor={[carParkLayoutInfo.coordinates[1], carParkLayoutInfo.coordinates[0]]} offset={[0.0001, 0.0001]}>
                                  <Card title={carParkLayoutInfo.name}>
                                    <p>{carParkLayoutInfo.name}</p>
                                    <p>{carParkLayoutInfo.privateCar.space}</p>
                                  </Card>
                                </Overlay>
                              }
                      </Map>             
                    </Col>
                  </Row>
                </div>
            </Content>
          }
        </div>
  );
}

export default CarParkAllDetail;