import { Link } from "react-router-dom"
import {Card,Button,Layout,Typography} from "antd"
import { useContext,useEffect, useState,useRef } from 'react';
import {Context} from './Utils/MyContext';


const WeatherDeatailCard = ({image,price,title,comment,count,setCount}) => {
  // const [count,setCount] = useState(0);
  const [subTotal,setSubTotal] = useState(0);
  const{totalAmount,setTotalAmount} = useContext(Context);
  const increment = () => {
    setCount(count + 1)
  }

  const decrement = () => {
    if (count !== 0){
      setCount(count - 1)
    }
  }
  
  useEffect(() => {
    setTotalAmount(totalAmount - subTotal)
    setSubTotal(count * price)
    },[count])

  useEffect(() => {
    setTotalAmount(subTotal + totalAmount)
  },[subTotal])

  
    return(
        <div>
          <Card hoverable title={title} className='card'>
              <img src={image}
                style={{height:"200px"}}/>
            <li>${price}</li>
            <li>{comment}</li>
            <Button onClick={increment} >+</Button>
            {count}
            <Button onClick={decrement} >-</Button>
            <li>小計 Sub-Total: ${subTotal}</li>
          </Card>
        </div> 
    );


}

export default WeatherDeatailCard;