import {Routes, Route} from 'react-router-dom';
import Home from './WeatherAllDetail';
import Detail from './WeatherDetail';
const WeatherRoutes = () => {
    return(

        <Routes>
            <Route exact path="/Details" element={<Home/>}/>
            <Route exact path="/Detail/:date" element={<Detail/>}/>
        </Routes>
    )

}
export default WeatherRoutes;